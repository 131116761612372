<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <b-img :src="require('@/assets/images/logo/logote.png')" />
                  </div>
                  <!-- <b-card-text class="mb-25">
                    Office
                  </b-card-text> -->
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <!-- <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title">คำสั่งซื้อ</h4>
                    <b-input-group
                      class="input-group-merge invoice-edit-input-group disabled"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.id"
                        disabled
                      />
                    </b-input-group>
                  </div> -->
                  <div class="d-flex align-items-center mb-1">
                    <span class="title"> วันที่สั่งซื้อ : </span>
                    <flat-pickr
                      v-model="date"
                      class="form-control invoice-edit-input"
                      :disabled="true"
                      :config="{ dateFormat: 'd-m-Y H:i' }"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="8" class="mb-2">
                  <b-form-group label=" ชื่อตัวแทน :" label-cols-md="4">
                    <v-select
                      v-model="selectUser"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userOptions"
                      label="label"
                      :clearable="false"
                    >
                      <!-- <template #list-header>
                        <li
                          v-b-toggle.sidebar-invoice-add-new-customer
                          class="add-new-client-header d-flex align-items-center my-50"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            size="16"
                          />
                          <span class="align-middle ml-25">Add New Customer</span>
                        </li>
                      </template> -->
                    </v-select>
                  </b-form-group>

                  <!-- Select Client -->

                  <!-- Selected Client -->
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="12"
                  cols="12"
                  class="mt-xl-0 mt-5 justify-content-start d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">เปิดบิลให้กับ :</h6>
                    <div v-if="selectUser.value" class="mt-1">
                      <h6 class="mb-25">
                        ชื่อ - นามสกุล : {{ selectUser.label }}
                      </h6>
                      <b-card-text class="mb-25">
                        เบอร์โทรศัพท์ : {{ selectUser.phone }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        ชื่อผู้ใช้ : {{ selectUser.username }}
                      </b-card-text>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 pb-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="1"> ลำดับ </b-col>
                        <b-col cols="12" lg="3"> รายการสินค้า </b-col>
                        <b-col cols="12" lg="2"> ราคา (บาท) </b-col>
                        <b-col cols="12" lg="2"> คงเหลือ </b-col>
                        <b-col cols="12" lg="2"> จำนวน </b-col>
                        <b-col cols="12" lg="2"> ราคารวม (บาท) </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col style="padding-top: 0.7rem" cols="12" lg="1">
                          {{ index + 1 }}
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="productOptions"
                            label="label"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="เลือกรายการสินค้า"
                            @input="(val) => reloadUpdateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Price</label>
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="mb-2"
                            @input="(val) => reloadUpdatePriceForm(index, val)"
                          />
                        </b-col>

                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Balance</label>
                          <b-form-input
                            v-model="item.realBalance"
                            type="number"
                            class="mb-2"
                            disabled
                          />
                        </b-col>

                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                            @input="(val) => reloadUpdateQtyForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Price</label>
                          <b-form-input
                            v-model="item.amount"
                            type="number"
                            class="mb-2"
                            @input="(val) => reloadUpdateTotalPrice(index, val)"
                            @change="
                              (val) => reloadUpdateTotalPrice(index, val)
                            "
                          />
                          <!-- <p class="mb-1">
                            {{
                              (item.price * item.qty || 0).toLocaleString(
                                "en-US"
                              ) || 0
                            }}
                          </p> -->
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                + เพิ่มสินค้า
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end mb-3"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper" style="max-width: 15rem">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">ราคารวมทั้งหมด</p>
                      <p class="invoice-total-amount" style="color: red">
                        <!-- {{ amount.toLocaleString("en-US") }} -->
                        <!-- {{ invoiceData.items.map(amount).reduce(sum) }} -->
                        {{ amount }}
                      </p>
                      <p class="invoice-total-title">บาท</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="handlerConfirmOpenbill"
          >
            บันทึก
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./invoiceStoreModule";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    ToastificationContent,
    BImg,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    handlerConfirmOpenbill() {
      const userData = getUserData();
      var productList = [];
      var productForAddCart = [];
      this.invoiceData.items.forEach((item) => {
        productList.push({
          productId: item.itemTitle.value,
          qty: item.qty,
          price: item.price,
          realBalance: item.realBalance,
        });
        productForAddCart.push({
          cartId: "00000000-0000-0000-0000-000000000000",
          productId: item.itemTitle.value,
          contactId: "00000000-0000-0000-0000-000000000000",
          userId: this.selectUser.value,
          qty: item.qty,
          price: item.price,
          minPrice: 0,
          maxPrice: 0,
          totalPrice: item.amount,
        });
      });

      const saveModel = {
        orderType: "openbill",
        userId: this.selectUser.value,
        // orderLists: productList,
        contactAddressId: "00000000-0000-0000-0000-000000000000",
        orderStatus: 1,
        orderPayment: 0,
        inventoryId: this.invenId,
      };
      store
        .dispatch("adminopenbill/handlerOpenbill", productForAddCart)
        .then((res) => {
          console.log("res", res);
          if (res.status == 200) {
            store
              .dispatch("adminopenbill/handlerConfirmOpenbillNew", saveModel)
              .then((res) => {
                if (res.status == 200) {
                  console.log("res", res);
                  this.$router
                    .push({ name: "admin-openbill-list" })
                    .then(() => {
                      this.showToast(
                        "success",
                        "CheckIcon",
                        "Your Work has been saved"
                      );
                    });
                }
              })
              .catch((error) => {
                this.showToast("danger", "XCircleIcon", error);
              });
          }
        })
        .catch((error) => {
          this.showToast("danger", "XCircleIcon", error);
        });
    },
    reloadUpdateQtyForm(index, val) {
      setTimeout(() => {
        //console.log(val);

        
        /*
        if (
          parseInt(val) > parseInt(this.invoiceData.items[index].realBalance)
        ) {
          this.invoiceData.items[index].qty =
            this.invoiceData.items[index].realBalance;
        } else {
          this.invoiceData.items[index].qty = val;
        }
        */

        this.invoiceData.items[index].qty = val;

        //this.invoiceData.items[index].qty = parseInt(val) > parseInt(this.invoiceData.items[index].realBalance) ? parseInt(this.invoiceData.items[index].realBalance) : parseInt(val);
        this.invoiceData.items[index].amount =
          this.invoiceData.items[index].price *
          this.invoiceData.items[index].qty;
        this.sumTotalData();
      }, 100);
    },

    reloadUpdatePriceForm(index, val) {
      this.invoiceData.items[index].price = val;
      this.invoiceData.items[index].amount =
        this.invoiceData.items[index].price * this.invoiceData.items[index].qty;
      this.sumTotalData();
    },

    reloadUpdateTotalPrice(index, val) {
      this.invoiceData.items[index].amount = val;
      this.sumTotalData();
    },
    reloadUpdateItemForm(index, val) {
      console.log(val);
      this.invoiceData.items[index].price = val.price;
      this.invoiceData.items[index].qty = 1;
      this.invoiceData.items[index].amount = val.price;
      this.invoiceData.items[index].realBalance = val.realBalance;
      this.sumTotalData();
    },
    sumTotalData() {
      var amount = this.invoiceData.items
        .map((item) => parseFloat(item.amount) || 0)
        .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
      //console.log("amount", amount);
      this.amount = parseFloat(amount);
    },
  },
  data() {
    return {
      date: new Date(),
      index: 1,
    };
  },
  watch: {
    invoiceData: {
      handler(newValue, oldValue) {
        // console.log(newValue, oldValue);
        this.amount = 0;

        this.invoiceData.items.forEach((item) => {
          this.amount += parseFloat(item.amount || 0);
        });
      },
      deep: true,
    },
  },
  computed: {
    sumTotal() {
      return this.invoiceData.items
        .map((item) => item.amount || 0)
        .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "adminopenbill";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const clients = ref([]);
    // store.dispatch('app-invoice/fetchClients')
    //   .then(response => { clients.value = response.data })
    const userOptions = ref([]);
    const productOptions = ref([]);
    store.dispatch("adminopenbill/fetchMyProducts").then((response) => {
      response.data.data.forEach((item) => {
        productOptions.value.push({
          value: item.productId,
          label: item.productCode,
          price: item.productPrice,
          productCode: item.productCode,
          realBalance: item.realBalance,
          qty: 1,
          minPrice: 0,
          maxPrice: 0,
        });
      });
    });

    const selectUser = ref({});
    store.dispatch("adminopenbill/fetchUsersOption").then((response) => {
      response.data.data.forEach((item) => {
        if (item.active && item.roleId.roleName == "ตัวแทนขาย") {
          userOptions.value.push({
            value: item.userId,
            label: item.username,
            phone: item.phone,
            userCode: item.userCode,
            username: item.username,
          });
        }
      });
    });

    const invenId = ref("");
    store.dispatch("adminopenbill/fetchInventory").then((response) => {
      invenId.value = response.data.data[0].inventoryId;
    });

    const itemFormBlankItem = {
      item: null,
      price: null,
      qty: null,
      description: "",
      realBalance: 0,
    };

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: "",
      note: "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
      paymentMethod: null,
    });

    const itemsOptions = [
      {
        itemTitle: "App Design",
        cost: 24,
        qty: 1,
        description: "Designed UI kit & app pages.",
      },
      {
        itemTitle: "App Customization",
        cost: 26,
        qty: 1,
        description: "Customization & Bug Fixes.",
      },
      {
        itemTitle: "ABC Template",
        cost: 28,
        qty: 1,
        description: "Bootstrap 4 admin template.",
      },
      {
        itemTitle: "App Development",
        cost: 32,
        qty: 1,
        description: "Native App Development.",
      },
    ];

    const updateItemForm = (index, val) => {
      const { price, qty } = val;
      amount;
      invoiceData.value.items[index].price = price;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].amount = price * qty;
    };

    const updateQtyForm = (index, val) => {
      invoiceData.value.items[index].qty = val;
      invoiceData.value.items[index].amount =
        invoiceData.value.items[index].price *
        invoiceData.value.items[index].qty;
    };

    const updatePriceForm = (index, val) => {
      invoiceData.value.items[index].price = val;
    };

    const updateTotalPrice = (index, val) => {
      console.log(index);
      invoiceData.value.items[index].amount = val;
      console.log(index, invoiceData.value.items[index].amount);
      // console.log(val);
      // invoiceData.value.items[index].amount = val;
    };

    const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];
    const amount = ref(0);

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      updatePriceForm,
      updateTotalPrice,
      itemFormBlankItem,
      paymentMethods,
      userOptions,
      productOptions,
      updateQtyForm,
      selectUser,
      amount,
      invenId,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
