import axios, { baseURL } from '@axios'
import { paginateArray, sortCompare } from '@/common'
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductsOption(ctx) {
      // const userData = getUserData()
      const payload = {
        key: "",
        dateFrom: null,
        dateTo: null,
        status: null
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/GetByKey`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMyProducts(ctx) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/SubInventory/GetSubInventoryByProductGroup`,  {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
      },
      fetchInventory(ctx) {
        const userData = getUserData()
        const inven = "คลังตัวแทน"
        return new Promise((resolve, reject) => {
          axios
            .get(`${baseURL}/api/Inventory/GetByKey?key=${inven}`,  {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchUsersOption(ctx) {
      const payload = {
        key: "",
        dateFrom: null,
        dateTo: null,
        status: null
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/User/GetByKey`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    handlerConfirmOpenbill(ctx, product) {
      const userData = getUserData()
      console.log('product', product)
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/AddOrUpdate`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    handlerConfirmOpenbillNew(ctx, product) {
      const userData = getUserData()
      console.log('product', product)
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/Add`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    handlerOpenbill(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Cart/AddList`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
